import { rafcService } from '../../helpers/backend/rafc.service';

export const state = {
    rafc: [],
    rafcNouaceur: {},
    manageStatus: null,
}

export const getters = {
    getRafc(state) {
        return state.rafc;
    },
    getRafcNouaceur(state) {
        return state.rafcNouaceur;
    },
}

export const actions = {
    getRafcBy({ commit }, params) {
        return rafcService.getRafc(params)
            .then(
                rafc => {
                    commit("RAFC_SUCCESS", rafc)
                    return Promise.resolve(rafc);
                },
                error => {
                    return Promise.reject(error);
                }
            );
    },
    manageRAFC({commit}, params) {
        return rafcService.manageRafc(params)
            .then(
                res => {
                    commit("MANAGE_SUCCESS", res)
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
    },
    clearRafc({ commit }) {
        commit("CLEAR_RAFC_SUCCESS")
    },
}

export const mutations = {
    RAFC_SUCCESS(state, rafc) {
        state.rafc = rafc;
    },
    RAFC_NOUACEUR_SUCCESS(state, rafc) {
        state.rafcNouaceur = rafc;
    },
    CLEAR_RAFC_SUCCESS(state) {
        state.rafc = {};
    },
    MANAGE_SUCCESS(state, status){
        state.manageStatus = status;
    }
}